<template>
<div class="price">
    <div v-if="discounted && price && comparePrice" class="item sale">
        {{ discountPercentage }}%
    </div>
    <div v-if="comparePrice && discounted" class="item paragraph-s" :class="{ strikethrough: discounted }">
        {{ compareAtPriceString }}
    </div>
    <div v-if="priceString" class="paragraph item price">{{ priceString }}</div>
</div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import Shopify from '@jackboxgames/shopify'
import { localizedPriceString } from '$services/storefronts/storefronts'

export default defineComponent({
    props: {
        product: {
            type: Object as PropType<Shopify.Shopify.Product | Shopify.Shopify.Variant>,
            required: true
        }
    },
    computed: {
        isVariant(): boolean {
            return (<Shopify.Shopify.Variant> this.product).price !== undefined
        },
        discounted() {
            const p = this.price
            const cp = this.comparePrice
            if (!p || !cp) return
            return (Number(p.amount) < Number(cp.amount))
        },
        discountPercentage() {
            if (!this.discounted) return
            const p = this.price
            const cp = this.comparePrice
            if (!p || !cp) return 0
            const price = Number(p.amount)
            const compareAtPrice = Number(cp.amount)
            return Math.round(((price - compareAtPrice) / compareAtPrice) * 100)
        },
        priceString(): string | undefined {
            const p = this.price
            if (!p) {
                return undefined
            }
            const mp = this.maxPrice
            if (!mp || mp.amount === p.amount) {
                return this.formatPrice(p)
            }
            return `${this.formatPrice(p)} - ${this.formatPrice(mp)}`
        },
        compareAtPriceString(): string | undefined {
            const p = this.comparePrice
            if (!p) {
                return undefined
            }
            const mp = this.maxComparePrice
            if (!mp || mp.amount === p.amount) {
                return this.formatPrice(p)
            }
            return `${this.formatPrice(p)} - ${this.formatPrice(mp)}`
        },
        price(): Shopify.Shopify.Price | undefined {
            return this.priceObj()
        },
        maxPrice(): Shopify.Shopify.Price | undefined {
            return this.priceObj(false, true)
        },
        comparePrice(): Shopify.Shopify.Price | undefined {
            return this.priceObj(true, false)
        },
        maxComparePrice(): Shopify.Shopify.Price | undefined {
            return this.priceObj(true, true)
        }

    },
    methods: {
        formatPrice(price: Shopify.Shopify.Price): string {
            return localizedPriceString(price.amount, price.currencyCode, this.$i18n.locale)
        },
        priceObj(compare = false, isMax = false): Shopify.Shopify.Price | undefined {
            const v = (<Shopify.Shopify.Variant> this.product)
            if (v.price !== undefined) {
                if (compare) {
                    return v.compareAtPrice
                }
                return v.price
            }
            const p = (<Shopify.Shopify.Product> this.product)
            let r = p.priceRange
            if (compare) {
                r = p.compareAtPriceRange
            }
            if (isMax) {
                return r?.maxVariantPrice
            }
            return r?.minVariantPrice
        }
    }
})
</script>

<style lang="scss" scoped>
.price {
    display: flex;
    align-items: center;
    gap: 12px;
    .sale {
        border-radius: 100px;
        color: var(--surface-900);
        background-color: var(--green-300);
        padding: 3px 8px;
        font-size: 10px;
        line-height: 10px;
        font-weight: 600;
    }
    .strikethrough {
        text-decoration: line-through;
        color: var(--neutral-300);
        font-size: 13px;
        line-height: 13px;
        font-weight: 500;
    }
    .item.price {
        font-size: 16px;
        line-height: 16px;
        font-weight: 700;
    }
}
</style>
